






















































































































































import { Component, Vue } from 'vue-property-decorator';
import FilterBroker from '@/components/Broker/Filter.vue';
import { savedSearchModule } from '@/store/modules/savedSearch';
import moment from 'moment';
import { Filter } from '@/interfaces/filter';
import { agentModule } from '@/store/modules/agent';

@Component({
    components: { FilterBroker },
    filters: {
        formatMoment(value: string) {
            if (!value) return '';
            return moment(value).locale('es').fromNow();
        }
    }
})
export default class SavedSearch extends Vue {
    multipleSelection = [];
    onlyAssociated = false;

    get tableData() {
        return savedSearchModule.searches.filter(({ clientePrincipal, _id }) =>
            this.onlyAssociated ? !clientePrincipal : clientePrincipal
        );
    }

    get disaffiliated() {
        return agentModule.disaffiliated;
    }

    filterData(filter: Filter) {
        savedSearchModule.setFilter(filter);
        savedSearchModule.index();
    }

    remove(data: { _id: string }[]) {
        savedSearchModule.remove(data.map(({ _id }) => _id));
    }

    mounted() {
        if (this.$route.query.typeFilter) {
            this.filterData(this.$route.query as Filter);
        }
    }

    destroyed() {
        savedSearchModule.clearSearches();
    }
}
