import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { Filter } from '@/interfaces/filter';
import { Customer } from '@/interfaces/customer';

@Module({ generateMutationSetters: true })
export class SavedSearchModule extends VuexModule {
    dialog = false;
    savedSearchSelected: any | null = null;
    searches: any[] = [];

    get customers() {
        if (!this.savedSearchSelected) return [];
        return this.savedSearchSelected.customers;
    }

    filter: Filter = {
        page: 1
    };

    @Action
    async closeOrOpenDialog() {
        this.dialog = !this.dialog;
    }

    @Action
    async selectSavedSearch(savedSearch?: any) {
        this.savedSearchSelected = savedSearch;
    }

    @Action
    async savedSearch(body: any) {
        await Axios.post('agente/guardar-busqueda', body);
    }

    @Action
    async setFilter(filter: Filter) {
        this.filter = Object.assign(this.filter, filter);
    }

    @Action
    async clearSearches() {
        this.searches = [];
    }

    @Action
    async index() {
        const { data } = await Axios.get('busqueda', {
            params: this.filter
        });
        this.searches = data;
    }

    @Action
    async remove(ids: string[]) {
        await Axios.delete('customers/eliminar-busquedas', {
            data: { ids }
        });
        this.searches = this.searches.filter(({ _id }) => !ids.includes(_id));
    }

    @Action
    async editSearch(body: any) {
        await Axios.post(
            `agente/editar-busqueda-v2/${this.savedSearchSelected._id}`,
            { ...this.savedSearchSelected.filter, ...body }
        );
    }

    @Action
    async setCustomers(body: Pick<Customer, 'email' | '_id'>) {
        this.savedSearchSelected = {
            ...this.savedSearchSelected,
            customers: this.savedSearchSelected.customers.map(
                (customer: Customer) => {
                    if ( customer._id === body._id) {
                        return { ...customer, ...body };
                    }
                    return { ...customer };
                }
            )
        };
    }
}
export const savedSearchModule = new SavedSearchModule({
    store,
    name: 'savedSearch'
});
